const API_BASE_URL = `${process.env.REACT_APP_BASE_URL}`

function verifyFailure(message) {
    return { isSuccess: false, message: message || 'Something went wrong' }
}

function verifySuccess() {
    return { isSuccess: true, message: 'Congratulation!' }
}

export async function changePassword(userKey, expirationKey, password, controller) {
    if (userKey === null || userKey === undefined || userKey.length === 0 ||
        expirationKey === null || expirationKey === undefined || expirationKey.length === 0) {
        return verifyFailure()
    }
    const request = {
        'userKey': userKey,
        'expirationKey': expirationKey,
        'rawNewPassword': password,
    }
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/users/passwords/forgot/confirm`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
            signal: controller?.signal
        })
        if (response.status !== 200) {
            return verifyFailure('Something went wrong')
        }
        return verifySuccess()
    } catch (e) {
        return verifyFailure('Server is not available right now')
    }
}

export async function verifyEmail(verifyCode, controller) {
    if (verifyCode === null || verifyCode === undefined || verifyCode.length === 0) {
        return verifyFailure()
    }
    const request = {
        key: verifyCode
    }
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/users/email/bound/confirm`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(request),
            signal: controller?.signal
        })
        if (response.status !== 200) {
            return verifyFailure('Something went wrong')
        }
        return verifySuccess()
    } catch (e) {
        return verifyFailure('Server is not available right now')
    }
}