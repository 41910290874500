import {HomePage} from "./Home/HomePage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {EmailVerifyPage} from "./EmailVerify/EmailVerifyPage";
import {ChangePasswordPage} from "./ChangePassword/ChangePasswordPage";
import {Footer} from "./Footer/Footer";

export const App = () => {
    return (
        <div className="root">
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/bound_email/:verifyCode' element={<EmailVerifyPage />} />
                    <Route path='/change_password/:userKey/:expirationKey' element={<ChangePasswordPage />} />
                    <Route path='/*' element={<Navigate to='/' />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </div>
    )
}