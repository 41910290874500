import logo from '../assets/images/logo.svg';
import {useEffect, useReducer} from "react";
import {useParams} from "react-router-dom";
import {verifyEmail} from "../Core/Api";

const getBody = ({ isLoading, result }) => {
    if (isLoading) {
        return (
            <>
                <h1 className="verify__activity_title">
                    Verifying...
                </h1>
                <p className="verify__activity_text">
                    It may take up to few minutes, please wait
                </p>
            </>
        )
    }
    if (result.isSuccess) {
        return (
            <>
                <h1 className="verify__activity_title">
                    { result.message }
                </h1>
                <p className="verify__activity_text">
                    You successfully verified your email.
                </p>
            </>
        )
    } else {
        return (
            <>
                <h1 className="verify__activity_title">
                    { result.message }
                </h1>
                <p className="verify__activity_text">
                    We could not verify your email address.<br />
                    Please, try to use different one or try again later!
                </p>
            </>
        )
    }
}

export const EmailVerifyPage = () => {
    const { verifyCode } = useParams()
    const [verifyState, dispatchState] = useReducer((state, action) => {
        return { isLoading: false, result: action }
    }, {}, () => {
        return {
            isLoading: true,
            result: null
        }
    })
    useEffect(() => {
        const controller = new AbortController()
        verifyEmail(verifyCode, controller)
            .then(e => dispatchState(e))
            .catch(e => !controller.signal.aborted && dispatchState({ isSuccess: false, message: 'Something went wrong' }))
        return () => controller.abort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <main className="main">
            <section className="container">
                <header className="header">
                    <img
                        src={logo}
                        alt="Logo"
                        className="logo__img"
                    />
                    <p>FanTik</p>
                </header>
                <section className="wrapper">
                    <div className="about__activity">
                        { getBody(verifyState) }
                        <div className="buttons__links">
                            <a href="/" className="btn btn_appStore">
                                <p>App Store</p>
                            </a>
                            <a href="/" className="btn btn_googlePlay">
                                <p>Google Play</p>
                            </a>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    )
}